import "./App.css";
import Slide from "./components/slides.js";

function App() {
  return (
    <div>
      <Slide />;
    </div>
  );
}

export default App;
